
import { Routes, Route } from "react-router-dom";

import Layout from "./layout/Layout";
import Home from "./pages/Home";
import Exchange from "./pages/Exchange";


import Modal from "react-modal";
import { useEffect, useState } from "react";
import "./App.css";


function App() {

  const [modalIsOpen, setModalIsOpen] = useState(true);

  useEffect(() => {
    setModalIsOpen(true);
  }, []);

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const customStyles = {
    content: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      width: "50%",
      height: "fit-content",
      margin: "auto",
      padding: "5%",
      background: "#4287d6",
      color: 'white',
      borderRadius: "15px",
    },
  };


  return (
    <>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Welcome Modal"
        style={customStyles}
      >
        <h2 className="heading-in-modal">Information!!</h2>
        <p className="custom-paragraph">Staking has been temporarily suspended as a precautionary measure following a recent hack attack on our whale address. If you have already staked, you will be able to unstake once your staking period has ended. Rest assured, we will soon be resuming the staking feature.</p>
        <button className="gradient-button" onClick={closeModal}>Close</button>
      </Modal>

      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index path="/" element={<Home />}></Route>
          <Route path="/Exchange" element={<Exchange />}></Route>
        </Route>
      </Routes>
    </>
  );
}

export default App;
