import React from 'react';
import { Link } from 'react-router-dom';

import useMetaMask from "../hook/index";

function Header() {

	const {
		connect,
		disconnect,
		isActive,
		account,
		walletModal,
		handleWalletModal,
		chainId
	} = useMetaMask();

	return (

		<nav className="navbar navbar-default navbar-trans navbar-expand-lg ">
			<div className="container">

				<Link className="navbar-brand text-brand" to="/"><img src="images/logo.png" alt="cenx" /><span></span></Link>
				<div className="navbar-collapse collapse justify-content-left" id="navbarDefault">
					<ul className="navbar-nav">
						<li className="nav-item">
							<Link className="nav-link" to="/" title="Stake"> Stake </Link>
						</li>
						<li className="nav-item">
							<Link className="nav-link" to="/Exchange" title="Trade"> Trade </Link>
						</li>

						{/* <li className="nav-item dropdown"> <a className="nav-link dropdown-toggle down-arrow" href="#" title="Industries" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> Trade</a>
							<div className="dropdown-menu" aria-labelledby="navbarDropdown">
								<Link className="dropdown-item" title="Exchange" to="/Exchange">Exchange</Link>

							</div>
						</li> */}
						<li className="nav-item"><a className="nav-link" href="/#" title="Supports" data-toggle="modal" data-target="#myModal-14"> Support </a></li>

						<div className="modal" id="myModal-14">
							<div className="modal-dialog">
								<div className="modal-content">
									<div className="modal-header">
										<h4 className="modal-title">Support</h4>
										<button type="button" className="close" data-dismiss="modal">&times;</button>
									</div>
									<div className="modal-body">
										<div className="liquidity">
											<h4>Write email at support@birtisetoken.com for queries</h4>
										</div>
									</div>
								</div>
							</div>
						</div>

					</ul>
				</div>
				<div className="right-side">
					<ul>
						<li>
							{isActive ? (
								<div className="btn">
									<div className="icon">
										<svg
											viewBox="0 0 24 24"
											color="primary"
											width="24px"
											xmlns="http://www.w3.org/2000/svg"
											className="sc-bdnxRM ACFFk"
										>
											<path
												fillRule="evenodd"
												clipRule="evenodd"
												d="M17 4C18.5 4 19 4.5 19 6L19 8C20.1046 8 21 8.89543 21 10L21 17C21 19 20 20 17.999 20H6C4 20 3 19 3 17L3 7C3 5.5 4.5 4 6 4L17 4ZM5 7C5 6.44772 5.44772 6 6 6L19 6L19 8L6 8C5.44772 8 5 7.55229 5 7ZM17 16C18 16 19.001 15 19 14C18.999 13 18 12 17 12C16 12 15 13 15 14C15 15 16 16 17 16Z"
											></path>
										</svg>
									</div>
									<div className="title">
										{account
											? account
												.slice(0, 4)
												.concat(`...${account.slice(-4)}`)
											: ""}
									</div>
									<svg
										viewBox="0 0 24 24"
										color="text"
										width="24px"
										xmlns="http://www.w3.org/2000/svg"
										className="sc-bdnxRM kDWlca"
									>
										<path d="M8.11997 9.29006L12 13.1701L15.88 9.29006C16.27 8.90006 16.9 8.90006 17.29 9.29006C17.68 9.68006 17.68 10.3101 17.29 10.7001L12.7 15.2901C12.31 15.6801 11.68 15.6801 11.29 15.2901L6.69997 10.7001C6.30997 10.3101 6.30997 9.68006 6.69997 9.29006C7.08997 8.91006 7.72997 8.90006 8.11997 9.29006Z"></path>
									</svg>

									<div className="popover__content">
										<div
											className="product-body"
											onClick={() => disconnect()}
										>
											<button className="color">
												<div
													className="left"
												// onClick={() => props.disconnect()}
												>
													Disconnect
												</div>
												<div className="right">
													<svg
														viewBox="0 0 24 24"
														width="24px"
														xmlns="http://www.w3.org/2000/svg"
														style={{ fill: "#6600ff" }}
													>
														<path d="M16.3 8.09014C15.91 8.48014 15.91 9.10014 16.3 9.49014L18.2 11.3901H9C8.45 11.3901 8 11.8401 8 12.3901C8 12.9401 8.45 13.3901 9 13.3901H18.2L16.3 15.2901C15.91 15.6801 15.91 16.3001 16.3 16.6901C16.69 17.0801 17.31 17.0801 17.7 16.6901L21.29 13.1001C21.68 12.7101 21.68 12.0801 21.29 11.6901L17.7 8.09014C17.31 7.70014 16.69 7.70014 16.3 8.09014ZM4 19.3901H11C11.55 19.3901 12 19.8401 12 20.3901C12 20.9401 11.55 21.3901 11 21.3901H4C2.9 21.3901 2 20.4901 2 19.3901V5.39014C2 4.29014 2.9 3.39014 4 3.39014H11C11.55 3.39014 12 3.84014 12 4.39014C12 4.94014 11.55 5.39014 11 5.39014H4V19.3901Z"></path>
													</svg>
												</div>
											</button>
										</div>
									</div>
								</div>
							) : (
								<a
									className="sr-btn"
									onClick={() => handleWalletModal(true)}
								>
									Connect Wallet
								</a>
							)}
						</li>

						<div id="walletConnectModal"
							className={`modal ${walletModal ? "show" : ""}`}
							style={{
								display: `${walletModal ? "block" : "none"}`,
							}}>
							<div className="modal-dialog">
								<div className="modal-content">
									<div className="modal-header">
										<h4 className="modal-title">Connect Wallet</h4>
										<button
											type="button"
											className="close"
											data-dismiss="modal"
											onClick={() => handleWalletModal(false)}
										>
											&times;
										</button>
									</div>

									<div className="modal-body">
										<div className="btn-connect"
											onClick={() =>
												connect("metaMask")
											}>
											<h1 className="connect">
												metamask
											</h1>
											<img src="images/meta.png" alt="metamask" />
										</div>
										<div className="btn-connect"
											onClick={() =>
												connect("bscWalletConnect")
											}>
											<h1 className="connect">
												Connect Wallet
											</h1>
											<img src="images/wallet-connect.png" alt="wallet-connect" />
										</div>
									</div>
								</div>
							</div>
						</div>
					</ul>
				</div>

				<button className="navbar-toggler collapsed" type="button" data-toggle="collapse" data-target="#navbarDefault" aria-controls="navbarDefault" aria-expanded="false" aria-label="Toggle navigation"> <span></span> <span></span> <span></span> </button>
			</div>
		</nav>

	)
}

export default Header
